.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 90px 0 0;
  /* box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1); */
  z-index: 99;

  background-color: #dc143c;
  color:white;
  margin-top: 60px;
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 8.5rem;
    /* top : 150px; */
    padding: 0;
  }
}

.navbar {
  /* box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .1); */
  background-color: #f4f4f4;
}

@media (min-width: 767.98px) {
  .navbar {
    top: 0;
    position: sticky;
    z-index: 999;
  }
}

.sidebar .nav-link {
  //color: #333;
  color:lightgray;
}

.sidebar .nav-link.active {
  color: #0d6efd;
  color:white;
}

.navbar-brand{
  color: white;
}

.navbar-brand img{
  width: 60px;
  height: auto;
}
