/*
Bootstrap の変数をここで上書きして Bootstrap のスタイルを調整
Bootstrap の変数は node_modules/bootstrap/scss/_variables.scss を参照
*/
@import '~sweetalert2/src/sweetalert2';
@import '~@sweetalert2/themes/dark/dark.scss';

@import "~bootstrap/scss/bootstrap.scss";
@import "_admin.scss";
@import "_user.scss";

