

  body.admin{
    color:black;
    letter-spacing: 2px;

    padding-top: 55px;


  @media (max-width: 767px) {
    .navbar-toggleable-sm {
      clear: both;
    }

  }

  .container {
    h2 {
      padding: 1rem 2rem;
      margin-bottom: 16px;
      color: white;
      background-image: -webkit-gradient(linear, left top, right top, from(#dc143c), to(#dc143c));
      background-image: -webkit-linear-gradient(left, #864944 0%, #dc143c 100%);
      background-image: linear-gradient(to right, #dc143c 0%, #dc143c 100%);
    }
  }

  #nav {
    .navbar-brand {
      color: white;

      img {
        width:200px;
      }
    }

    #nav .nav-link {
      color: white;
    }
  }

  .navbar {
    background: linear-gradient(90deg, #dc143c 0%, #dc143c 100%);
  }

  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;

    .checkbox {
      font-weight: 400;
    }

    .form-control {
      position: relative;
      box-sizing: border-box;
      height: auto;
      padding: 10px;
      font-size: 16px;
    }

    .form-control:focus {
      z-index: 2;
    }

    input[type="email"] {
      margin-bottom: -1px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    input[type="password"] {
      margin-bottom: 10px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .badge-default {
    font-size: 100%;
  }

  .form-pickup {
    font-size: 2rem;
    text-align: center;
  }

  .form-horizontal {
    float: left;
    margin-right: .5rem;
  }
  .form-check{

    margin-bottom: 0.125rem;
  }
  table.profile {
    width: 100%;
    border-collapse: collapse;

    tr {
      border-bottom: solid 2px white;
    }

    tr:last-child {
      border-bottom: none;
    }

    th {
      position: relative;
      text-align: left;
      width: 30%;
      background-color: #dc143c;
      opacity: 0.8;
      color: #000;
      text-align: center;
      padding: 10px 0;
    }

    td {
      text-align: left;
      width: 70%;
      text-align: center;
      background-color: #dc143c;
      opacity: 0.8;
      padding: 10px 0;
    }

  }

  .background-wrapper {
    position: fixed;
    width: 100%;
    height: 1000px;
    overflow: hidden;
    z-index: -1;
  }

  .background-wrapper .bg1 {
    position: fixed;
    top: 0px;
    content: '';
    display: block;
    width: 102%;
    height: 100%;
    background-image: url(./../assets/img/background.png);
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
    animation: zoom 60s linear infinite;
    animation-fill-mode: forwards;
    z-index: -1;
  }

  .background-wrapper:before {
    position: fixed;
    top: 0px;
    content: '';
    display: block;
    width: 102%;
    height: 100%;
    background-image: url(./../assets/img/background.png);
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
    animation: zoom2 60s linear infinite;
    animation-fill-mode: forwards;
    z-index: -3;
  }

  @keyframes zoom {

    0% {
      transform: translateX(95%);
      //filter: hue-rotate(1800deg) brightness(0.6);
      filter: brightness(0.6);
    }
    to {
      transform: translateX(0%);
      filter: brightness(0.6);
    }
  }

  @keyframes zoom2 {

    0% {
      transform: translateX(0%);
      filter: brightness(0.6);
    }
    to {
      transform: translateX(-95%);
      filter: brightness(0.6);
    }
  }


  .card-header:first-child {
    border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
  }

  .card-header {
    background-color: black;
    border: white solid;
    border-bottom: 0px;
  }

  .card-body{
    background: black;
    border: white solid;
    border-top: 4px white dotted;
    border-bottom: none;
    padding: 1rem 1rem 0rem 1rem;
  }

  .card-footer{
    border: white solid;
    background-color: black;
    border-top: none;
    border-radius: 0 0 calc(0.5rem - 1px) calc(0.5rem - 1px);
    text-align: center;
    padding-bottom: 16px;
  }


  .modal-header:first-child {
    border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
  }

  .modal-header{
    border-bottom: 0px;
    background-color: black;
    border: white solid;
    border-bottom: none;
  }
  .modal-body{
    background: black;
    border: white solid;
    border-top:  white dotted;
    border-bottom: none;
  }

  .modal-footer{
    background-color: black;
    border:  white solid;
    border-top: none;
    border-radius: 0 0 calc(0.5rem - 1px) calc(0.5rem - 1px);
  }

  .btn.btn-primary{
    background-color: black;
    border: 1px white solid;
    border-radius: 2px;
  }

  .btn-cursor::before{
    content : '> ';
  }

  table {
    th {
      color:#000
    }
    tr {
      color:#000;
    }
    td {
      color:#000;
    }
  }


  @-webkit-keyframes typing {
    from { width: 0 }
    to { width:16.3em }
  }

  @-moz-keyframes typing {
    from { width: 0 }
    to { width:16.3em }
  }

  @-webkit-keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: black }
  }

  @-moz-keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: black }
  }


  }
